
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../hooks/useHandleRequests";

const SingedInReRouting = () => {
  const { user, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  const [userMeta, setUserMeta] = useState({});

  const navigate = useNavigate();

  console.log("Auth0 User", user);

  const userMetadata = user.user_metadata || {};

  const { apiRequest: getMetaData } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {
      console.log("AUTH-0 Data", data);
      setUserMeta(data?.user);
    },
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        setIsLoading(true);
        await getMetaData(`/v3/user`);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getUserMetadata();
  }, [isAuthenticated]);

  console.log("SIGNED IN REROUTING", userMetadata);

  useEffect(() => {
    if (isLoading === false) {
      if (
        userMeta === undefined ||
        userMeta === null ||
        Object.keys(userMeta).length === 0
      ) {
        navigate("/on-boarding");
      } else if (
        (window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.tokenHandler) ||
        (window.Android && window.Android.handleToken)
      ) {
        console.log("Opened in WebView");
        navigate("/auth-token");
      } else {
        navigate("/all-notes");
      }
      navigate("/all-notes");
    }
  }, [userMeta]);

  return <></>;
};

export default SingedInReRouting;
