// import React from 'react';
// import Grid from '@mui/material/Grid2'
// import { SignIn as SignInComponent
// } from '@clerk/clerk-react';
// import { Box, Stack, Typography } from '@mui/material';

// const SignIn = () => {
//   return (
//     <Grid sx={{ bgcolor: 'white'}} container component="main">
//       <Grid size={{ lg: 6, md: 6, sx: 12, xs: 12}} display="flex" height="100vh" justifyContent="center" alignItems="center">
//         <SignInComponent
//           signUpFallbackRedirectUrl="/all-notes"
//           fallbackRedirectUrl="/all-notes"
//           signUpUrl='/sign-up'
//         />
//       </Grid>
//       <Grid sx={{
//         backgroundImage: `url('/images/SignUpDog.png')`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         overflow: 'hidden',
//       }} size={{ lg: 6, md: 6, sx: 0, xs: 0}} height="100vh">
//         {/* <Stack direction="column" justifyContent="center" height="100vh" alignItems="center" display="flex">
//           <img src="/images/drh new logo.jpeg" width="100%"  />
//         </Stack> */}
//         <Box
//           display="flex"
//           flexDirection="column-reverse"
//           gap={1}
//           width='100%'
//           height='100%'
//           alignItems='start' // Centers content horizontally
//           px="100px"
//           sx={{ position: 'relative', zIndex: 1, background: 'linear-gradient(to bottom, transparent 50%, #FFFFFF 80%)' }}
//         >
//           <Typography
//             textAlign='start'
//             fontSize='22px'
//             fontWeight={600}
//             color='black'
//             marginBottom='30px'
//           >
//             <span style={{ color: '#FE4D66' }}>Download </span> the mobile app
//             at{' '}
//             <a
//               style={{ color: '#FE4D66', fontStyle: 'normal' }}
//               href='https://www.downloadtime.ai'
//               target='_blank'
//               rel='noopener noreferrer'
//             >
//               www.downloadtime.ai
//             </a>
//           </Typography>
//           <Typography
//             textAlign='start'
//             fontSize='22px'
//             fontWeight={600}
//             variant='h3'
//             color='black'
//           >
//             For <span style={{ color: '#FE4D66' }}>Tutorials </span> visit{' '}
//             <a
//               style={{ color: '#FE4D66', fontStyle: 'normal' }}
//               href='https://www.drh.ai/guides'
//               target='_blank'
//               rel='noopener noreferrer'
//             >
//               www.drh.ai/guides
//             </a>
//           </Typography>
//           <Typography
//             textAlign='start'
//             fontSize='54px'
//             fontWeight={700}
//             variant='h3'
//             color='black'
//           >
//             {`Automated `}
//             <span style={{ color: '#FE4D66' }}>AI </span>
//             {`Notes`}
//           </Typography>
//           <Typography
//             textAlign='start'
//             fontSize='54px'
//             fontWeight={700}
//             variant='h3'
//             color='black'
//           >
//             {`Reclaim your`} <span style={{ color: '#FE4D66' }}>TIME</span>
//           </Typography>
//         </Box>
//       </Grid>
//     </Grid>
//   )
// }

// export default SignIn
import React from "react";
import Grid from "@mui/material/Grid2";
import { AppBar, Toolbar, Button, Box, Typography } from "@mui/material";
import Login from "../../components/autho0LogIn/Login";

const SignIn = () => {
  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: "white",
          overflow: "hidden",
          background: "linear-gradient(to bottom, white, rgba(0, 0, 0, 0.1))", // Gradient effect
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: "24px",
          }}
        >
          {/* Logo */}
          <Box display="flex" alignItems="center" gap={2}>
            <img
              src="/images/drh logo.png"
              alt="Logo"
              width="200px"
              style={{ borderRadius: "8px" }}
            />
          </Box>
          <Login />
        </Toolbar>
      </AppBar>
      <Grid
        sx={{ bgcolor: "white", overflow: "hidden" }}
        container
        component="main"
      >
        <Grid
          size={{ lg: 6, md: 6, sx: 12, xs: 12 }}
          display="flex"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column-reverse">
            <Typography
              textAlign="start"
              fontSize="22px"
              fontWeight={600}
              color="black"
              marginBottom="30px"
            >
              <span style={{ color: "#FE4D66" }}>Download </span> the mobile app
              at{" "}
              <a
                style={{ color: "#FE4D66", fontStyle: "normal" }}
                href="https://www.downloadtime.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.downloadtime.ai
              </a>
            </Typography>
            <Typography
              textAlign="start"
              fontSize="22px"
              fontWeight={600}
              variant="h3"
              color="black"
            >
              For <span style={{ color: "#FE4D66" }}>Tutorials </span> visit{" "}
              <a
                style={{ color: "#FE4D66", fontStyle: "normal" }}
                href="https://www.drh.ai/guides"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.drh.ai/guides
              </a>
            </Typography>
            <Typography
              textAlign="start"
              fontSize="54px"
              fontWeight={700}
              variant="h3"
              color="black"
            >
              {`Automated `}
              <span style={{ color: "#FE4D66" }}>AI </span>
              {`Notes`}
            </Typography>
            <Typography
              textAlign="start"
              fontSize="54px"
              fontWeight={700}
              variant="h3"
              color="black"
            >
              {`Reclaim your`} <span style={{ color: "#FE4D66" }}>TIME</span>
            </Typography>
          </Box>
        </Grid>
        <Grid
          sx={{
            backgroundImage: `url('/images/SignUpDog.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
          size={{ lg: 6, md: 6, sx: 0, xs: 0 }}
          height="100vh"
        >
          {/* <Stack direction="column" justifyContent="center" height="100vh" alignItems="center" display="flex">
          <img src="/images/drh new logo.jpeg" width="100%"  />
        </Stack> */}
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
