import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
  Grid2 as Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IOSSwitch } from "../styled";
import { subscriptionBenefits } from "../../utils";
import { Check } from "@mui/icons-material";
import useApiRequest from "../../hooks/useHandleRequests";

const Subscription = () => {
  const [subscriptionType, setSubscriptionType] = useState("Monthly");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const { apiRequest: getSubscriptionsData, loading: IsFetching } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => {
        setSubscriptionData(data?.products || []);
      },
      showSuccessSnackbar: false,
    });

  useEffect(() => {
    getSubscriptionsData("/v3/stripe/products");
  }, []);

  const { apiRequest: createInvoice, loading: isCreatingInvoice } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => {
        const url = data?.intentObj?.latest_invoice?.hosted_invoice_url;
        window.open(url, "_blank", "noopener,noreferrer");
      },
      successMessage: "Invoice has been created",
    });

  if (IsFetching)
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        margin="15px"
      >
        <CircularProgress />
      </Stack>
    );

  return (
    <Box component="div">
      <Typography
        textAlign="left"
        component="h3"
        fontWeight={700}
        lineHeight="3rem"
        fontSize="32px"
      >
        The right plan for you, and for your time.
      </Typography>
      <Typography
        textAlign="left"
        marginTop="5px"
        component="h5"
        variant="body1"
        fontWeight={500}
        fontSize="16px"
      >
        Extend your capabilities, get more done with your time, and help more
        patients than ever. Choose the plan that fits your needs the best.
      </Typography>
      <Stack
        margin="10px 0px"
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Typography color="grey" fontSize="12px" fontWeight={700}>
          Monthly
        </Typography>
        <IOSSwitch
          sx={{ fontSize: "36px" }}
          onChange={() => {
            setSubscriptionType((type) =>
              type === "Monthly" ? "Yearly" : "Monthly"
            );
          }}
        />
        <Box gap={0.5} display="flex" alignItems="center">
          <Typography
            color="grey"
            fontSize="12px"
            fontWeight={700}
            component="span"
          >
            Yearly
          </Typography>
          <Typography
            color="rgb(34 197 94)"
            fontSize="12px"
            fontWeight={700}
            component="span"
          >
            {` (Save more!)`}
          </Typography>
        </Box>
      </Stack>
      {subscriptionData.map((data) => {
        const price =
          data.prices &&
          data.prices.find((price) =>
            price?.lookup_key.includes(subscriptionType.toLowerCase())
          );
        console.log(price);
        return (
          <Card
            sx={{
              maxWidth: 400,
              borderRadius: 3,
              boxShadow: 3,
              p: 3,
              margin: "0px auto",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {data?.product?.name}
              </Typography>
              <Typography
                variant="h3"
                component="div"
                sx={{ fontWeight: "bold", mt: 2 }}
              >
                {subscriptionType === "Monthly"
                  ? `$${(price?.unit_amount || 0) / 100}`
                  : `$${(price?.unit_amount || 0) / 1200}`}{" "}
                <Typography variant="body1" component="span">
                  /month
                </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
                {data?.product?.description}
              </Typography>
              <Stack direction="column" spacing={2}>
                {data.product?.metadata?.features &&
                  data.product?.metadata?.features
                    .split(",")
                    .map((option, index) => (
                      <Box key={index} display="flex" alignItems="center">
                        <Check color="primary" />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                          {option}
                        </Typography>
                      </Box>
                    ))}
              </Stack>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mt: 2,
                marginLeft: 2,
              }}
            >
              {isCreatingInvoice ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={() =>
                    createInvoice("v3/stripe/subscription", "post", {
                      priceId: price?.id,
                    })
                  }
                  variant="contained"
                  sx={{ backgroundColor: "#2E6FF3" }}
                  size="small"
                >
                  Buy now
                </Button>
              )}
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};

export default Subscription;
