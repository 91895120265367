// import React, { useEffect, useState } from 'react';
// import './app.css';
// import Layout from './features/layout/Layout';
// import CustomNoteTemplate from './features/pages/CustomNoteTemplate';
// import Analytics from './features/pages/Analytics';
// import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
// import AllNotes from './features/pages/AllNotes';
// import { SignedIn, SignedOut, useUser } from '@clerk/clerk-react';
// import SignUp from './features/pages/SignUp';
// import SignIn from './features/pages/SignIn';
// import NoteDetails from './features/pages/NoteDetails';
// import Onboarding from './features/pages/OnBoarding';
// import SendTokenMobile from './features/pages/SignInMobile';
// import SingedInReRouting from './components/SingedInReRouting';
// import TestComponnet from './components/NewTextArea';
// import SignedOutRouting from './components/SignedOutRouting';
// import Subscription from './components/subscription/Subscription';
// import NewNoteDetailsContent from './components/newNoteDetailsContent/NewNoteDetailsContent';
// import { tourSteps } from './utils';
// import Reports from './features/pages/Reports';

// function App() {
//   const { isSignedIn } = useUser();

//   // useEffect(() => {
//   //   const initializeCrisp = () => {
//   //     window.$crisp = [];
//   //     window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
//   //     const d = document;
//   //     const s = d.createElement('script');
//   //     s.src = 'https://client.crisp.chat/l.js';
//   //     s.async = 1;
//   //     d.getElementsByTagName('head')[0].appendChild(s);
//   //   };

//   //   if (isSignedIn) {
//   //     initializeCrisp();
//   //   }

//   //   return () => {
//   //     if (window.$crisp) {
//   //       delete window.$crisp;
//   //       delete window.CRISP_WEBSITE_ID;
//   //       const crispScript = document.querySelector(
//   //         "script[src='https://client.crisp.chat/l.js']"
//   //       );
//   //       if (crispScript) crispScript.remove();
//   //     }
//   //   };
//   // }, [isSignedIn]);

//   return (
//     <BrowserRouter>
//       <SignedOut>
//         <Routes>
//           <Route path='/' element={<SignIn />} />
//           <Route path='/sign-up' element={<SignUp />} />
//           <Route path='*' element={<SignedOutRouting />} />
//         </Routes>
//       </SignedOut>
//       <SignedIn>
//         <Routes>
//           <Route path='/on-boarding' element={<Onboarding />} />
//           <Route path='/auth-token' element={<SendTokenMobile />} />
//           <Route element={<Layout />}>
//             <Route path='/test' element={<NewNoteDetailsContent />} />
//             <Route
//               path='/all-notes'
//               element={<AllNotes tourSteps={tourSteps} />}
//             />
//             <Route path='/all-notes/:id' element={<NoteDetails />} />
//             <Route
//               path='/custom-note-template'
//               element={<CustomNoteTemplate />}
//             />
//             <Route path='/analytics' element={<Analytics />} />
//             <Route path='/reports' element={<Reports />} />
//             <Route path='/subscription' element={<Subscription />} />
//           </Route>
//           <Route path='*' element={<SingedInReRouting />} />
//         </Routes>
//       </SignedIn>
//     </BrowserRouter>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import "./app.css";
import Layout from "./features/layout/Layout";
import CustomNoteTemplate from "./features/pages/CustomNoteTemplate";
import Analytics from "./features/pages/Analytics";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import AllNotes from "./features/pages/AllNotes";
//import { SignedIn, SignedOut } from "@clerk/clerk-react";
import SignUp from "./features/pages/SignUp";
import SignIn from "./features/pages/SignIn";
import NoteDetails from "./features/pages/NoteDetails";
import Onboarding from "./features/pages/OnBoarding";
import SendTokenMobile from "./features/pages/SignInMobile";
import SingedInReRouting from "./components/SingedInReRouting";
import TestComponnet from "./components/NewTextArea";
import SignedOutRouting from "./components/SignedOutRouting";
import Subscription from "./components/subscription/Subscription";
import NewNoteDetailsContent from "./components/newNoteDetailsContent/NewNoteDetailsContent";
import { tourSteps } from "./utils";
import Reports from "./features/pages/Reports";
// import NotePrompt from "./features/pages/NotePrompt";
// import Macros from "./components/customPhrases/Macros";
// import NewNoteTemplate from "./features/pages/NewNoteTemplate";
// import PatientPrompt from "./features/pages/PatientPrompt";
// import UnderDevelopment from "./components/UnderDevelopment";
import { CircularProgress, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  // useEffect(() => {
  //   const initializeCrisp = () => {
  //     window.$crisp = [];
  //     window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
  //     const d = document;
  //     const s = d.createElement('script');
  //     s.src = 'https://client.crisp.chat/l.js';
  //     s.async = 1;
  //     d.getElementsByTagName('head')[0].appendChild(s);
  //   };

  //   if (isSignedIn) {
  //     initializeCrisp();
  //   }

  //   return () => {
  //     if (window.$crisp) {
  //       delete window.$crisp;
  //       delete window.CRISP_WEBSITE_ID;
  //       const crispScript = document.querySelector(
  //         "script[src='https://client.crisp.chat/l.js']"
  //       );
  //       if (crispScript) crispScript.remove();
  //     }
  //   };
  // }, [isSignedIn]);

  const { isLoading, error, user, isAuthenticated, loginWithRedirect, logout } =
    useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Optional: Full-page height
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {!isAuthenticated && (
        <>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="*" element={<SignedOutRouting />} />
          </Routes>
        </>
      )}
      {isAuthenticated && (
        <Routes>
          <Route path="/on-boarding" element={<Onboarding />} />
          <Route path="/auth-token" element={<SendTokenMobile />} />
          <Route element={<Layout />}>
            <Route path="/test" element={<NewNoteDetailsContent />} />
            <Route
              path="/all-notes"
              element={<AllNotes tourSteps={tourSteps} />}
            />
            <Route path="/all-notes/:id" element={<NoteDetails />} />
            <Route
              path="/custom-note-template"
              element={<CustomNoteTemplate />}
            />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/subscription" element={<Subscription />} />
          </Route>
          <Route path="*" element={<SingedInReRouting />} />
        </Routes>
      )}
    </>
  );
}

export default App;
