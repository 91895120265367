import { Add, ArrowBack, Close, ContentCopy, Mic } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IOSSwitch } from "../../components/styled";
import TextArealayout from "../../components/TextArealayout";
import useApiRequest from "../../hooks/useHandleRequests";
import {
  clinicalDecesionTools,
  getCopyContentFromValue,
  notevalues,
  statasesvalues,
  tourStepsNoteDetails,
} from "../../utils";
import { useCopyToClipboard } from "../../hooks/useCopyFromClipBoard";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNoteDetials,
  setnoteDetails,
} from "../../slices/noteDetialsSlice";
import UpdateNoteDialog from "../../components/dialogs/updateNoteDialog";
import { enqueueSnackbar } from "notistack";
// import { useUser } from "@clerk/clerk-react";
import { closeTutorial } from "../../slices/tutorialSlice";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import { useAuth0 } from "@auth0/auth0-react";

const enableStripe = process.env.REACT_APP_ENABLE_STRIPE;

const NoteDetails = () => {
  //const { user } = useUser();
  const { user } = useAuth0();

  //const createdAt = new Date(user.createdAt);
  const createdAt = new Date(user.updated_at);

  const currentDate = new Date();
  const diffInMs = currentDate - createdAt;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const isMoreThan30Days = diffInDays >= 30;
  const location = useLocation();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  useEffect(() => {
    const match = location.pathname.match(/^\/all-notes\/\d+$/);
    if (match) {
      setIsCurrentPage(true);
    } else {
      setIsCurrentPage(false);
    }
  }, [location]);
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
  const [tour, setTour] = useState(false);
  const startTour = () => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourStepsNoteDetails(!!followupID),
      onDestroyStarted: () => {
        setTour(false);
        localStorage.setItem(`hasSeenTour_/note-details`, "true");
        dispatch(closeTutorial());
        driverObj.destroy();
      },
    });

    driverObj.drive();
  };
  useEffect(() => {
    if (tour) {
      startTour();
    }
  }, [tour]);
  useEffect(() => {
    const tourSeen = localStorage.getItem(`hasSeenTour_/note-details`);
    if (user?.publicMetadata?.tutorial === 0 && tourSeen === "false") {
      setTour(true);
    }
  }, []);
  useEffect(() => {
    if (isTutorialOpen && isCurrentPage) {
      setTour(true);
    }
  }, [isTutorialOpen]);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    summary,
    followup,
    note,
    followupID,
    status,
    summary_json,
    followup_json,
  } = useSelector((state) => state.noteDetails.noteDetails);
  const [excludedHeadings, setExcludedHeadings] = useState({
    summary_json: [],
    followup_json: [],
  });

  //using state here
  //using state here 2.0
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(
    "Oops! It looks like you don't have an active subscription. To unlock this feature, please subscribe to our plan and enjoy full access. Click below to get subscribe!"
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleContinue = () => {
    handleCloseModal();
    navigate("/subscription");
  };
  const [selectedAction, setSelectedAction] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const { tool } = useSelector((state) => state.clinicalTool);
  const { apiRequest: getSingleNote, loading: loadingNoteDetails } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => dispatch(setnoteDetails(data)),
      showSuccessSnackbar: false,
    });
  const { apiRequest: getStripeStatus, loading: loadingStripeStatus } =
    useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) =>
        setSubscriptionStatus(data.hasActiveSubscription),
      showSuccessSnackbar: false,
    });

  const handleClose = () => {
    setSelectedAction(null);
    getSingleNote(`/v3/note/${id}`);
  };

  const handleExcludedHeadings = (key, heading, checked) => {
    if (checked) {
      setExcludedHeadings((headings) => ({
        ...headings,
        [key]: headings[key].filter((item) => item !== heading),
      }));
    } else {
      setExcludedHeadings((headings) => ({
        ...headings,
        [key]: [...headings[key], heading],
      }));
    }
  };

  const getCopyAllData = () => {
    let content = "Note:\n";

    // Loop through all properties in summary_json
    if (summary_json) {
      content += Object.values(summary_json).reduce((prev, value) => {
        return prev + getCopyContentFromValue(value) + "\n";
      }, "");
    }

    // Loop through all properties in followup_json
    if (followup_json) {
      content += "\n\n\nDisposition:\n";
      content += Object.values(followup_json).reduce((prev, value) => {
        return prev + getCopyContentFromValue(value) + "\n";
      }, "");
    }

    return content;
  };

  const getCopySelectedData = () => {
    let content = "Note:\n";

    // Loop through summary_json and exclude headings if needed
    if (summary_json) {
      content += Object.values(summary_json).reduce((prev, value) => {
        return (
          prev +
          `${
            excludedHeadings.summary_json.includes(value.heading)
              ? ""
              : getCopyContentFromValue(value)
          }\n`
        );
      }, "");
    }

    // Loop through followup_json and exclude headings if needed
    if (followup_json) {
      content += "\n\n\nDisposition:\n";
      content += Object.values(followup_json).reduce((prev, value) => {
        return (
          prev +
          `${
            excludedHeadings.followup_json.includes(value.heading)
              ? ""
              : getCopyContentFromValue(value)
          }\n`
        );
      }, "");
    }

    return content;
  };

  const { copyHandler: copyAll } = useCopyToClipboard(getCopyAllData());
  const { copyHandler: copySelected } = useCopyToClipboard(
    getCopySelectedData()
  );

  useEffect(() => {
    getSingleNote(`/v3/note/${id}`);
    // getStripeStatus(`/v3/stripe/status`);
    return () => {
      dispatch(resetNoteDetials());
    };
  }, []);
  useEffect(() => {
    if (!tool) {
      const interval = setInterval(() => {
        getSingleNote(`/v3/note/${id}`);
        return () => {
          dispatch(resetNoteDetials());
        };
      }, 60000);
      // 60 sec delay 
      return () => clearInterval(interval);
    }
  }, [summary_json, note, tool]);
  const copyEnable =
    user?.publicMetadata?.isSuperAdmin ||
    !isMoreThan30Days ||
    (isMoreThan30Days && subscriptionStatus);
  return (
    <React.Fragment>
      <Stack gap={2} direction="row" marginBottom="15px">
        <Button
          id="backToDashboard"
          sx={{ color: "#2E6FF3" }}
          onClick={() => {
            navigate("/all-notes");
            dispatch(resetNoteDetials());
          }}
          variant="text"
          startIcon={<ArrowBack />}
        >
          Back to Dashboard
        </Button>
        {Object.values(notevalues).map((noteValue, index) => (
          <Button
            id={`noteValue${index}`}
            startIcon={<Mic />}
            color="error"
            size="small"
            disabled={
              followup_json ||
              !summary_json ||
              summary_json.length <= 0 ||
              loadingNoteDetails
            }
            disableElevation
            onClick={() => setSelectedAction(noteValue)}
            variant="contained"
          >
            {noteValue.label}
          </Button>
        ))}
      </Stack>
      {loadingNoteDetails ? (
        <Stack
          justifyContent="center"
          marginTop="30px"
          gap={2}
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2">{statasesvalues[status]}</Typography>
        </Stack>
      ) : (
        <>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            marginBottom="15px"
          >
            <Typography
              component="span"
              color="#202224"
              fontSize="32px"
              fontWeight={700}
            >
              {note?.patient?.innitials} |{" "}
              {note?.patient?.sex && note?.patient?.sex[0]} |{" "}
              {note?.patient?.age} {note?.patient?.ageType}
            </Typography>
            <Chip
              sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
              id="copyAll"
              clickable
              variant="filled"
              icon={<ContentCopy />}
              label="Copy All"
              color="primary"
              onClick={() => {
                if (enableStripe === "true") {
                  if (!copyEnable) {
                    handleOpenModal();
                  } else {
                    copyAll();
                    enqueueSnackbar({
                      variant: "success",
                      message: "Content has been copied",
                    });
                  }
                } else {
                  copyAll();
                  enqueueSnackbar({
                    variant: "success",
                    message: "Content has been copied",
                  });
                }
              }}
            />
            <Chip
              sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
              clickable
              id="copySelected"
              variant="filled"
              icon={<ContentCopy />}
              label="Copy Selected"
              color="primary"
              onClick={() => {
                if (enableStripe === "true") {
                  if (!copyEnable) {
                    handleOpenModal();
                  } else {
                    copySelected();
                    enqueueSnackbar({
                      variant: "success",
                      message: "Selected Content has been copied",
                    });
                  }
                } else {
                  copySelected();
                  enqueueSnackbar({
                    variant: "success",
                    message: "Selected Content has been copied",
                  });
                }
              }}
            />
            <Chip
              sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
              clickable
              id="unSelect"
              variant="filled"
              icon={<Close />}
              label="Unselect All"
              color="primary"
              onClick={() => {
                setExcludedHeadings((headings) => ({
                  ...headings,
                  // Extract headings from summary_json (which is an object)
                  summary_json: summary_json
                    ? Object.values(summary_json).map(
                        (summary) => summary.heading
                      )
                    : headings.summary_json, // Keep existing values if summary_json is undefined

                  // Extract headings from followup_json (which is an object)
                  followup_json: followup_json
                    ? Object.values(followup_json).map(
                        (followup) => followup.heading
                      )
                    : headings.followup_json, // Keep existing values if followup_json is undefined
                }));
              }}
            />
          </Stack>
          <Grid container component="main" spacing={2}>
            {
              <Grid size={{ lg: 6, md: 6, sm: 12, xs: 12 }} id="noteDetails">
                {tool &&
                  (clinicalDecesionTools
                    .find((clincalTool) => clincalTool.tool === tool)
                    .componenet({
                      sex: note?.patient?.sex,
                      age: note?.patient?.age,
                      ageType: note?.patient?.ageType,
                    }) ||
                    null)}
                <TextArealayout
                  excludedHeadings={excludedHeadings.summary_json}
                  handleExcludedHeadings={handleExcludedHeadings}
                  id={note?.id}
                  title="Note"
                  noteKey="summary_json"
                  copyNoteKey="summary"
                />
              </Grid>
            }
            {!(followup === null || followup === undefined) && (
              <Grid size={{ lg: 6, md: 6, sm: 12, xs: 12 }} id="disposition">
                <TextArealayout
                  excludedHeadings={excludedHeadings.followup_json}
                  handleExcludedHeadings={handleExcludedHeadings}
                  id={followupID}
                  title="Disposition"
                  noteKey="followup_json"
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <UpdateNoteDialog
        data={note}
        type={selectedAction}
        handleClose={handleClose}
      />
      {/* Modal (Dialog) */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            padding: "20px",
            alignItems: "center",
            borderRadius: "16px",
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
            minWidth: "400px",
            bgcolor: "#f9f9f9",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "#2E6FF3" }}
        >
          Subscribe Now!
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ fontSize: "1rem", color: "#555", marginBottom: "20px" }}
          >
            {modalMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            sx={{
              color: "#fff",
              bgcolor: "gray",
              "&:hover": { bgcolor: "#b5b5b5" },
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleContinue}
            sx={{
              color: "#fff",
              bgcolor: "#2E6FF3",
              "&:hover": {
                bgcolor: "linear-gradient(45deg, #4CAF50 30%, #2E6FF3 90%)",
              },
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NoteDetails;
