import {
  Box,
  Button,
  ButtonGroup,
  Card as MCard,
  CardContent,
  Chip,
  Divider,
  Pagination,
  Select,
  Skeleton,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Link,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import {
  allNotestableHeadData,
  dummyNotes,
  getFilteredNotes,
  getFilterSelectedNoteTypeParams,
  getFormatedDateAndTime,
  getNotestableHeaders,
  getNoteStatusInfo,
  getReportsHeaders,
  locations,
  reportsDummyData,
} from "../../utils";
import {
  removeNote,
  setCurrentPage,
  setNotes,
  setNoteTypeFilter,
} from "../../slices/notesSlice";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";
import PatientInformationDialog from "../../components/dialogs/PatientInformationDialog";
import { useNavigate, useLocation } from "react-router-dom";
import useApiRequest from "../../hooks/useHandleRequests";
import YesNoDialog from "../../components/dialogs/YesNoDialog";
// import { useUser } from "@clerk/clerk-react";
import { closeTutorial } from "../../slices/tutorialSlice";
import CustomSelectInput from "../../components/CustomSelectInput";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(46, 111, 243, 0.1)",
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(46, 111, 243, 0.2)",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&": {
    color: "#202224",
    fontSize: "12px",
    fontWeight: 600,
  },
}));

const StyledPagination = styled(Pagination)(() => ({
  "&": {
    margin: "15px 0px",
  },
  "& ul": {
    justifyContent: "center",
  },
}));

const Reports = ({ tourSteps }) => {
  // const { user } = useUser();
  const location = useLocation();
  const [isAllNotesPage, setIsAllNotesPage] = useState(false);
  useEffect(() => {
    const match = location.pathname === "/all-notes";
    if (match) {
      setIsAllNotesPage(true);
    } else {
      setIsAllNotesPage(false);
    }
  }, [location]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notes, filters, pagination } = useSelector((state) => state.notes);
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [tour, setTour] = useState(false);

  console.log("notes", notes);
  const { apiRequest, loading } = useApiRequest({
    handleError: () => {},
    handleResponse: (data) => dispatch(setNotes(data)),
    showSuccessSnackbar: false,
  });

  const { apiRequest: deleteNote } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: () => {},
    successMessage: "Note has been deleted",
  });

  const handleDeleteNoteSelection = (e, id) => {
    e.stopPropagation();
    setDeleteNoteId(id);
  };

  const handleDelete = (id) => {
    deleteNote(`v3/note/${id}`, "delete");
    dispatch(removeNote(id));
    setDeleteNoteId(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      apiRequest(
        `/v3/note${
          getFilterSelectedNoteTypeParams[filters.noteType]
        }&paginate=true&limit=${pagination.notesPerPage}&page=${
          pagination.currentPage
        }&searchable=${filters.search}`,
        "get"
      );
    }, 60000);
    return () => clearInterval(interval);
  }, [pagination.currentPage, filters.noteType, filters.search]);

  useEffect(() => {
    apiRequest(
      `/v3/note${
        getFilterSelectedNoteTypeParams[filters.noteType]
      }&paginate=true&limit=${pagination.notesPerPage}&page=${
        pagination.currentPage
      }&searchable=${filters.search}`,
      "get"
    );
  }, [pagination.currentPage, filters.noteType, filters.search]);

  const handleOpen = (e, patientData) => {
    e.stopPropagation();
    setSelectedData(patientData);
    setOpenDialog(true);
  };
  const handleClose = () => setOpenDialog(false);
  const startTour = () => {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourSteps(notes.length === 0),
      onDestroyStarted: () => {
        setTour(false);
        localStorage.setItem(`hasSeenTour_/all-notes`, "true");
        dispatch(closeTutorial());
        driverObj.destroy();
      },
    });

    driverObj.drive();
  };
  useEffect(() => {
    if (tour) {
      startTour();
    }
  }, [tour]);
  useEffect(() => {
    const tourSeen = localStorage.getItem(`hasSeenTour_/all-notes`);
    // if (user?.publicMetadata?.tutorial === 0 && tourSeen === "false") {
    //   setTour(true);
    // }
  }, []);
  useEffect(() => {
    if (isTutorialOpen && isAllNotesPage) {
      setTour(true);
    }
  }, [isTutorialOpen]);

  const handleRowCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedRows((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((item) => item !== id)
      );
    }
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      // Select all rows (based on userID or another unique identifier)
      const allRowIds = reportsDummyData().map((report) => report.userID);
      setSelectedRows(allRowIds);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "32px",
          textAlign: "start",
          mb: 3,
          color: "#202224",
        }}
      >
        Reports
      </Typography>
      <MCard>
        <TableContainer id="noteTable">
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#2E6FF3" }}>
                {getReportsHeaders().map((item, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{ color: "white", fontWeight: 700, fontSize: "16px" }}
                  >
                    {item.isCheckbox ? (
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        inputProps={{ "aria-label": "Select all rows" }}
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#00B0FF",
                          },
                        }}
                      />
                    ) : (
                      item.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? [0, 1, 2, 3, 4, 5, 6].map((item) => (
                    <TableRow>
                      {[
                        0,
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        {
                          /* ...(user.publicMetadata.isSuperAdmin ? [7, 8] : []), */
                        },
                      ].map((item) => (
                        <TableCell key={item}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : reportsDummyData().map((report, index) => (
                    <StyledTableRow key={index} id={`noteTable${index}`}>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedRows.includes(report.userID)} // Check if this row is selected
                          onChange={(e) =>
                            handleRowCheckboxChange(e, report.userID)
                          }
                          inputProps={{ "aria-label": "select row" }}
                          sx={{
                            color: "#2E6FF3",
                            "&.Mui-checked": {
                              color: "#2E6FF3",
                            },
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {report.userID}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {report.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {report.totalNotes}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {report.pending}%
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {report.completed}%
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          href="/analytics"
                          underline="always"
                          sx={{
                            color: "#2E6FF3",
                            cursor: "pointer",
                            fontWeight: 400,
                          }}
                        >
                          View Details
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CardContent>
          <StyledPagination
            id="pagination"
            onChange={(e, value) => dispatch(setCurrentPage(value))}
            page={pagination.currentPage}
            color="primary"
            count={1}
            shape="rounded"
          />
        </CardContent>
      </MCard>
    </>
  );
};

export default Reports;
