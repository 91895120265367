// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { Provider } from 'react-redux';
// import store from './store';
// import { ClerkProvider } from '@clerk/clerk-react';
// import { SnackbarProvider } from 'notistack';
// import { Slide } from '@mui/material';

// const frontendApi = process.env.REACT_APP_CLERK_SECRET_KEY;
// const publishableKey = process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY;

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ClerkProvider
//     afterSignOutUrl="/"
//     frontendApi={frontendApi}
//     publishableKey={publishableKey}
//     appearance={{
//       layout: {
//         privacyPageUrl: "https://drh.ai/legal",
//         helpPageUrl: "https://drh.ai/contact",
//       },
//       elements: {
//         footerPages: {
//           flexDirection: "row-reverse",
//         },
//         footer: {
//           display: "flex",
//           flexDirection: "column-reverse",
//           gap: 6,
//         },
//         footerPagesLink__privacy: {
//           color: "rgb(16, 63, 239)",
//         },
//       },
//     }}
//     >
//       <Provider store={store}>
//         <SnackbarProvider autoHideDuration={2000} TransitionComponent={(props) => <Slide {...props} direction="left" />}  anchorOrigin={{horizontal: 'right', vertical: 'top'}} maxSnack={3} >
//           <App />
//         </SnackbarProvider>
//       </Provider>
//     </ClerkProvider>
//   </React.StrictMode>
// );

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
//import { ClerkProvider } from "@clerk/clerk-react";
import { SnackbarProvider } from "notistack";
import { Slide } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./components/AuthProvider.js";

// const frontendApi = process.env.REACT_APP_CLERK_SECRET_KEY;
// const publishableKey = process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    {/* <ClerkProvider
      afterSignOutUrl="/"
      frontendApi={frontendApi}
      publishableKey={publishableKey}
      appearance={{
        layout: {
          privacyPageUrl: "https://drh.ai/legal",
          helpPageUrl: "https://drh.ai/contact",
        },
        elements: {
          footerPages: {
            flexDirection: "row-reverse",
          },
          footer: {
            display: "flex",
            flexDirection: "column-reverse",
            gap: 6,
          },
          footerPagesLink__privacy: {
            color: "rgb(16, 63, 239)",
          },
        },
      }}
    > */}
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={2000}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        maxSnack={3}
      >
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
    {/* </ClerkProvider> */}
  {/* </React.StrictMode> */}
  </>
);
